.App {
  text-align: center;
  color: white;
  margin-right: 1rem;
  margin-left: 1rem;
}

.temp-banner {
  padding: 0.5rem;
  font-size: 2em;
  margin: 0;
}

.image-container {
  padding-bottom: 2rem
}

img {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}
