
.contact-image {
    padding-top: 1rem;
}


.email-tag {
    color: #ED35A8;
}

.contact-text {
    padding-top: 1rem;
    font-size: 1.5rem;
}