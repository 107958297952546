@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");

body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
    background: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

